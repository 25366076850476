<template>
  <div class="container">
    <ShowPath :path="path" />
    <Loading :spinner="spinner" />
    <div v-if="!spinner">
      <div class="view-content">
        <!-- code -->
        <PrismEditor
          v-if="!content.isImg && !content.md"
          v-model="content.code"
          readonly="true"
          :highlight="highlighter"
          :line-numbers="lineNumbers"
        />
        <!-- markdown -->
        <div v-if="content.md" v-html="markdownHtml"></div>
        <!-- imgs -->
        <ViewImage v-if="content.isImg" :image="content.code" />
      </div>
    </div>
  </div>
</template>

<script>
// -- PRISM EDITOR --
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-dark.css";
import "prismjs/components/prism-php.js";
import "prismjs/components/prism-php-extras.js";
import "prismjs/components/prism-markup-templating.js"; // <-- php dependency
import "prismjs/components/prism-clike.js";
import "prismjs/components/prism-javascript.js";
import "prismjs/components/prism-bash.js";
import "prismjs/components/prism-json.js";
import "prismjs/components/prism-python.js";
import { marked } from "marked";
// -- AXIOS + COMPONENTS --
import axios from "axios";
import Loading from "@/components/Loading.vue";
import ShowPath from "@/components/ShowPath.vue";
// -- IMAGE --
import ViewImage from "@/components/ViewImage.vue";

export default {
  name: "ViewContent",
  async created() {
    this.path = this.$route.path;
    await this.getContent();
  },
  components: {
    PrismEditor,
    Loading,
    ShowPath,
    ViewImage,
  },
  computed: {
    markdownHtml() {
      return marked(this.content.code);
    },
  },
  data() {
    return {
      content: { type: Object, default: {} },
      ext: { type: String, default: "" },
      type: { type: String, default: "" },
      spinner: { type: Boolean, default: false },
    };
  },
  props: {
    lineNumbers: { type: Boolean, required: false, default: true },
  },
  methods: {
    async getContent() {
      this.spinner = true;
      this.content = {};
      await axios
        .get(process.env.VUE_APP_API_PROD + "/?fc=" + this.path)
        .then((response) => {
          this.content = {
            name: response.data.name,
            code: response.data.code,
            ext: response.data.ext,
            type: response.data.type,
            isImg: response.data.isImg,
            md: response.data.ext === "md" ? true : false,
          };
          this.spinner = false;
        })
        .catch((err) => {
          this.spinner = false;
          this.$router.push({
            name: "Error",
            params: {
              0: this.path, //! <-- wtf?
              code: err.code,
              msg: err.msg,
            },
          });
        });
    },
    markdown(code) {
      return marked(code);
    },
    highlighter(code) {
      switch (this.content.ext) {
        case "php":
          return highlight(code, languages.php);
        case ("vue", "js"):
          return highlight(code, languages.javascript);
        case ("sh", null):
          return highlight(code, languages.bash);
        case "py":
          return highlight(code, languages.python);
        case "md":
          return this.markdown(code);
        default:
          return highlight(code, languages.javascript);
      }
    },
  },
};
</script>
<style>
.view-content {
  box-shadow: 0 4px 4px 0 var(--main-grey), 0 4px 4px 0 var(--main-front-color);
  margin: auto;
  border: 1px solid var(--main-grey);
  line-height: 1.5;
  padding: 5px;
  max-width: 100%;
}

/* "global text" -- all languages*/
/* javascript cool par ex (white), vue c'est boooo */
pre.prism-editor__editor {
  color: white;
}
pre.prism-editor__edirot {
  color: red;
}

.token.package {
  color: #a37ca4;
}
/* php this */
.token.this {
  color: #a37ca4;
}

.token.scope {
  color: #a37ca4;
}
</style>

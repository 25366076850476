<template>
  <div>
    <img class="img" :alt="`${this.img}`" :src="`${this.img}`" />
  </div>
</template>
<script>
export default {
  name: "ViewImage",
  async created() {
    this.img = await require("/public/ext/" + this.image);
  },
  data() {
    return {
      img: { type: String, default: "" },
    };
  },
  props: {
    image: { type: String, required: true },
  },
};
</script>
<style scoped>
.img {
  width: 100%;
  object-fit: cover;
}
</style>
